import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mb="30px" column center>
          <CFImage src={mobileAbout} w="90%" alt="Sakeya Sushi About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" pt="50px" m="0 auto">
          <CFImage src={about} alt="Promotions" w="81%" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
